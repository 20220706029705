import { Link } from "react-router-dom";

export default function NewsArticle({ source, date, title, content, links = [] }) {
  return (
    
      <div className="card rounded-5 shadow-sm mb-4">
        <div className="card-body p-3 p-md-4">
          <div className="article-meta mb-2">
            By : <span className="text-danger">{source}</span> / {date}
          </div>

          <h2 className="card-title mb-2">{title}</h2>

          <p className="card-text text-secondary mb-4 article-content">{content}</p>

          {links.length > 0 && (
            <div className="d-flex flex-wrap gap-2">
              {links.map((link, index) => (
                <a key={index} href={link.url}  target="_blank" rel="noopener noreferrer"  className="button button-primary m-0 bg-blue">{link.name} →</a>
              ))}
            </div>
          )}
        </div>
      </div>
  )
}

