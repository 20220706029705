import React from "react";
import Herosection from "./HeroSection";
import "./press.css";
import NewsArticle from "./NewsArticle";

const articleData = [
  {
    source: "ANI News",
    date: "Feb 10, 2025 21:28",
    title:
      "X-Biz Techventures Pvt. Ltd. achieves CERT-IN Empanelment, Elevating Cybersecurity Standards.",
    content:
      "X- Biz Techventures Pvt. Ltd., is proud to announce that it has achieved empanelment by the Indian Computer Emergency Response Team (CERT-IN), operating under the Ministry of Electronics and Information Technology (MeitY), Government of India. This empanelment signifies a significant milestone in X-Biz's commitment to providing robust, government-approved cybersecurity solutions that meet the highest standards of information security and compliance.",
    links: [
      {
        "name": "ANI News",
        "url": "https://aninews.in/news/business/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards20250203163813/"
      },
      {
        "name": "The Print",
        "url": "https://theprint.in/ani-press-releases/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards/2477333/"
      },
      {
        "name": "Lokmat Times",
        "url": "https://www.lokmattimes.com/business/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards/"
      },
      {
        "name": "The News Network",
        "url": "https://www.bignewsnetwork.com/news/275016306/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards"
      },
      {
        "name": "Dailyhunt",
        "url": "https://m.dailyhunt.in/news/india/english/ani+english-epaper-anieng/xbiz+techventures+pvt+ltd+achieves+certin+empanelment+elevating+cybersecurity+standards-newsid-n650397094?sm=Y"
      },
      {
        "name": "Newswire",
        "url": "https://www.newswireonline.com/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards/"
      },
      {
        "name": "LatestLY",
        "url": "https://www.latestly.com/agency-news/business-news-x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards-6615091.html"
      },
      {
        "name": "Event News",
        "url": "https://www.eventnews.blog/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards/"
      },
      {
        "name": "Real Estate Pro",
        "url": "https://realestatepro.cloud/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards/"
      },
      {
        "name": "Trends Stories",
        "url": "https://trendsstories.com/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards/"
      },
      {
        "name": "PR Digital World",
        "url": "https://prdigitalworld.com/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards/"
      },
      {
        "name": "Verified Brandz",
        "url": "https://www.verifiedbrandz.com/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards/"
      },
      {
        "name": "Facts Observer",
        "url": "https://factsobserver.com/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards/"
      },
      {
        "name": "Startup News Now",
        "url": "https://startupnewsnow.com/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards/"
      },
      {
        "name": "News Media",
        "url": "https://www.newsmedia.city/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards/"
      },
      {
        "name": "Business News",
        "url": "http://businessnews.in.net/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards/"
      },
      {
        "name": "European Suntimes",
        "url": "https://www.europeansuntimes.com/news/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards20250203163809/"
      },
      {
        "name": "New York Despatch",
        "url": "https://www.newyorkdespatch.com/news/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards20250203163809/"
      },
      {
        "name": "Washington DC Despatch",
        "url": "https://www.washingtondcdespatch.com/news/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards20250203163809/"
      },
      {
        "name": "Dubai City Reporter",
        "url": "https://www.dubaicityreporter.com/news/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards20250203163809/"
      },
      {
        "name": "White House NewsTime",
        "url": "https://www.whitehousenewstime.com/news/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards20250203163809/"
      },
      {
        "name": "Miami News Herald",
        "url": "https://www.miaminewsherald.com/news/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards20250203163809/"
      },
      {
        "name": "England News Portal",
        "url": "https://www.englandnewsportal.com/news/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards20250203163809/"
      },
      {
        "name": "France Network Times",
        "url": "https://www.francenetworktimes.com/news/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards20250203163809/"
      },
      {
        "name": "Toronto Suntimes",
        "url": "https://www.torontosuntimes.com/news/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards20250203163809/"
      },
      {
        "name": "Florida Breaking News",
        "url": "https://www.floridabreakingnews.com/news/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards20250203163809/"
      },
      {
        "name": "US World Today",
        "url": "https://www.usworldtoday.com/news/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards20250203163809/"
      },
      {
        "name": "World News Network",
        "url": "https://www.worldnewsnetwork.net/news/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards20250203163809/"
      },
      {
        "name": "London Channel News",
        "url": "https://www.londonchannelnews.com/news/x-biz-techventures-pvt-ltd-achieves-cert-in-empanelment-elevating-cybersecurity-standards20250203163809/"
      }
    ]
    
  },
  {
    source: "The Enterprise World ",
    date: "April 30, 2024 17:05 ",
    title:
      'X-Biz Techventures Private Limited – From Self-Funded Startup to FinTech Industry Leader.',
    content:
      "Under X-Biz Techventures’ “Innovate-Incubate-Invest” approach, the company’s innovation lab has successfully rolled out three distinct divisions. These divisions are DigiVision.ai, SecureNexus.io, and DigiDrive DMS. Specializing in advanced AI/ML/ Computer Vision, Cybersecurity and Document management capabilities respectively. ",
    links: [
      {
        name: "The Enterprise World",
        url: "https://theenterpriseworld.com/x-biz-techventures-private-limited/",
      },
    ],
  },
];

export const PressRelease = () => {
  return (
    <>
      <Herosection />
      <div className="container-xxl articles-wrapper py-5">
        {articleData.map((cur) => {
          return (
            <NewsArticle
              source={cur.source}
              date={cur.date}
              title={cur.title}
              content={cur.content}
              links={cur.links}
            />
          );
        })}
      </div>
    </>
  );
};
